<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <main class="main-container">
      <div>
        <div class="title-container">
          <img src="@/assets/logo_white.png" alt="Logo" class="logo" />
          <h2 class="app-title">Let's get started</h2>
          <h4 class="subheading">Create your account</h4>
        </div>
        <!-- <h2 class="app-title">Sign Up</h2> -->
        <!-- <br />
        <div>
          <div class="chip">Get 100$ flight credit</div>
          <div class="chip">Offer ends in {{ days }} days</div>
        </div> -->
        <!-- <br /> -->

        <!-- Error message -->
        <div class="error-message-container">
          <label class="error-message">{{ errorMessage }}</label>
        </div>

        <!-- Input fields with v-model binding -->
        <input
          type="text"
          id="name"
          placeholder="Enter your name"
          v-model="name"
        />
        <input
          type="text"
          id="email"
          placeholder="Enter your email"
          v-model="email"
        />
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          v-model="password"
        />
        <input
          type="password"
          id="confirm-password"
          placeholder="Confirm your password"
          v-model="confirmPassword"
        />

        <!-- Terms and conditions checkbox -->
        <!-- <div class="checkbox-container">
          <input class="checkbox" type="checkbox" id="terms" v-model="terms" />
          <label class="terms" for="terms">
            I agree to the terms and conditions
          </label>
        </div> -->

        <!-- Sign Up button with conditional disable -->
        <button
          type="submit"
          class="join-button"
          @click="submitForm"
          :disabled="isFormInvalid"
        >
          Sign Up
        </button>
        <div class="login-container">
          <h4 class="subheading">
            Already have an account?
            <router-link class="login-link" to="/"> Login </router-link>
          </h4>
        </div>
      </div>
    </main>
    <!-- <footer class="footer">
      <p>&copy; 2024 Skyhost. All rights reserved.</p>
    </footer> -->
  </div>
</template>

<script>
import { auth, db } from "@/plugins/firebase";
import { collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
      days: 0,
      departureTime: "Sep 30 2024, 01:00 pm",
      departsIn: "",
      intervalId: null,
      errorMessage: "",
      isSubmitting: false,
    };
  },
  computed: {
    isFormInvalid() {
      return (
        !this.name || !this.email || !this.password || !this.confirmPassword
      );
    },
  },
  methods: {
    submitForm() {
      console.log("Sign up form submitted");
      this.isSubmitting = true;

      // Validate form data
      if (this.isFormInvalid) {
        this.errorMessage = "Please fill in all the fields";
        this.isSubmitting = false;
        return;
      }

      // Check if email is valid
      if (!this.isValidEmail(this.email)) {
        this.errorMessage = "Please enter a valid email address";
        this.isSubmitting = false;
        return;
      }

      // Check if passwords match
      if (this.password !== this.confirmPassword) {
        this.errorMessage = "Passwords do not match";
        this.isSubmitting = false;
        return;
      }

      // Check if terms are accepted
      // if (!this.terms) {
      //   this.errorMessage = "Please agree to the terms and conditions";
      //   return;
      // }

      this.errorMessage = "";

      // Sign up user
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          console.log("Signed in as:", user.email);

          // create user entry in firestore
          const userData = {
            userId: user.uid,
            name: this.name,
            email: this.email,
            credit: 100,
            createdAt: new Date(),
          };
          const docRef = await addDoc(collection(db, "users"), userData);
          console.log("Document written with ID: ", docRef.id);
          this.isSubmitting = false;
          this.$router.push("/home");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          this.isSubmitting = false;

          if (errorCode === "auth/email-already-in-use") {
            this.errorMessage = "Email already in use";
            return;
          } else if (errorCode === "auth/invalid-email") {
            this.errorMessage = "Invalid email";
            return;
          } else if (errorCode === "auth/operation-not-allowed") {
            this.errorMessage = "Operation not allowed";
            return;
          } else if (errorCode === "auth/weak-password") {
            this.errorMessage = "Weak password";
            return;
          } else if (errorCode === "auth/user-disabled") {
            this.errorMessage = "User disabled";
            return;
          } else if (errorCode === "auth/user-not-found") {
            this.errorMessage = "User not found";
            return;
          } else if (errorCode === "auth/wrong-password") {
            this.errorMessage = "Wrong password";
            return;
          } else {
            this.errorMessage = "Unknown error";
            return;
          }
        });
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    updateDepartsIn() {
      const departureDate = new Date(this.departureTime);
      const now = new Date();
      const timeDiff = departureDate - now;
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      this.days = days;
      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        this.departsIn = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else {
        this.departsIn = "Departed";
        clearInterval(this.intervalId);
      }
    },
  },
  mounted() {
    this.intervalId = setInterval(this.updateDepartsIn, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
html,
body {
  overflow: hidden;
  height: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.header {
  padding: 1rem;
  background-color: #000;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
  flex-direction: row;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.terms {
  font-size: 0.8rem;
  color: #a8a8a8;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin-bottom: 2rem;
  padding: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 3rem;
}

.login-link {
  font-size: 0.8rem;
  color: #fff;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(238, 238, 238);
  margin: 1rem 0;
}

.error-message-container {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: rgb(209, 88, 88);
  font-size: small;
  margin-bottom: 1rem;
}

.logo {
  height: 30px;
  margin-bottom: 2rem;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.app-title {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}

.chip {
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.8rem;
  color: white;
  background-color: #3b3b3b;
  border-radius: 12px;
  /* margin-bottom: 2rem; */
  margin-right: 5px;
}

.label {
  font-weight: bold;
  color: #a8a8a8;
  font-size: 0.7rem;
}

.value {
  color: #000;
  font-size: 0.8rem;
}

.proceed-button {
  width: 100%;
  padding: 10px;
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  bottom: 20px;
}

.proceed-button:hover {
  background-color: #104e93;
}

.title {
  width: 400px;
  margin-bottom: 0.5rem;
}

.subheading {
  width: 100%;
  text-wrap: wrap;
  font-size: small;
  text-align: center;
  color: #b9b5b5;
}

.place {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  display: block;
  width: 100%;
  height: 45px;
  font-size: 14px;
  padding: 20px;

  color: inherit;
  font-family: inherit;

  background: #13141a;

  border-radius: 12px;
  border: 2px solid transparent;
  outline: none;
  margin-bottom: 20px;
}

input:focus {
  outline: none;
  border-color: #1565c0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #13141a inset !important;
  -webkit-text-fill-color: #fff !important;
  border-color: #13141a;
}

input:-webkit-autofill:focus {
  border-color: #1565c0;
}

.join-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #1565c0;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  height: 45px;
  width: 100%;
  margin-bottom: 1rem;
  &:hover {
    background-color: #104e93;
  }
  &:disabled {
    background-color: #104e93;
    color: #aaa;
    cursor: not-allowed;
  }
}

.btn-primary:hover {
  background-color: #0056b3;
}

.footer {
  padding: 1rem;
  /* background-color: #f8f8f8; */
  font-size: 0.7rem;
  color: gray;
}
</style>
