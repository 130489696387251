<template>
  <div class="peer-container">
    <!--show avatar if video is either muted or degraded-->
    <div
      v-if="!videoTrack?.enabled || videoTrack?.degraded"
      class="avatar-container"
    >
      <!-- <div ref="peerTileElement" class="peerTileElement"> -->
      <UserAvartar :name="peer.name" :peerId="peer.id" />
      <!-- </div> -->
      <div class="peer-name">
        {{ peer.isLocal ? "You" : peer.name }}
      </div>
    </div>
    <!-- <UVideo
      :mirror="peer.isLocal"
      :trackId="videoTrack?.id"
      :peerId="peer.id"
      :peerContainer="peerTileElement"
    /> -->
    <!-- <div class="network-score">
      <ConnectionQuality :peerId="peer.id" />
    </div> -->
    <div v-if="!isAudioEnabled" class="mic-state">
      <span><MicOffIcon /></span>
    </div>
  </div>
</template>

<script setup>
import { hmsStore } from "@/utils/hms";
import {
  selectCameraStreamByPeerID,
  selectIsPeerAudioEnabled,
} from "@100mslive/hms-video-store";
import { defineProps, onMounted, onUnmounted, ref } from "vue";
// import ConnectionQuality from "./ConnectionQuality.vue";
// import UVideo from "./UVideo.vue";
import UserAvartar from "./UserAvartar.vue";
import MicOffIcon from "./icons/MicOffIcon.vue";
// import MicIcon from "./icons/MicIcon.vue";
import { addAudioBorder } from "@/utils/audioLevel";
const props = defineProps({
  peer: {
    type: Object,
  },
});

let peerTileElement = ref();
let videoTrack = ref();
let isAudioEnabled = ref(false);

const unsub1 = hmsStore.subscribe((track) => {
  videoTrack.value = track;
}, selectCameraStreamByPeerID(props.peer.id));

const unsub2 = hmsStore.subscribe((enabled) => {
  isAudioEnabled.value = enabled;
}, selectIsPeerAudioEnabled(props.peer.id));

onMounted(() => addAudioBorder(props.peer.id, peerTileElement.value));
onUnmounted(() => {
  unsub1();
  unsub2();
});
</script>

<style lang="scss" scoped>
.peer-name {
  font-size: 9px;
  font-weight: 300;
  text-align: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// .peerTileElement {
//   transition: border 0.3s ease-in-out;
//   border: 2px solid transparent;
// }

.peer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 30px;
  position: relative;
  height: 50px;
  width: 50px;
  transition: width 0.3s ease-in-out;
  margin: 1px;
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 40px;
  height: 40px;
}

.network-score {
  position: absolute;
  bottom: 0.25rem;
  left: 0.25rem;
}

.mic-state {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: white;
  background: #f97474;
  border-radius: 100rem;
  width: 14px; /* Adjusted width for mic icon */
  height: 14px; /* Adjusted height for mic icon */
  padding: 0; /* Ensure no padding */
}

.mic-state > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.mic-state > span > svg {
  width: 6px; /* Adjusted icon size */
  height: 6px; /* Adjusted icon size */
}
</style>
