<template>
  <div id="player_id" style="width: 100%; height: 100%"></div>
</template>

<script>
/* global OvenPlayer */
export default {
  name: "OvenPlayerComponent",
  data() {
    return {
      isReloading: false,
      isStreaming: false,
    };
  },
  mounted() {
    this.loadOvenPlayerScript().then(() => {
      this.setupPlayer();
    });
  },
  methods: {
    loadOvenPlayerScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src =
          "https://cdn.jsdelivr.net/npm/ovenplayer/dist/ovenplayer.js";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    toggleStreaming() {
      this.isStreaming = !this.isStreaming;
      this.$emit("streaming-updated", this.isStreaming);
    },
    setupPlayer() {
      const player = OvenPlayer.create("player_id", {
        autoStart: true,
        controls: false,
        autoFallback: true,
        mute: true,
        sources: [
          {
            label: "jp_webrtc",
            type: "webrtc",
            file: "wss://milanstream.skyhost.tours:8443/app/stream",
          },
        ],
      });

      player.on("player_state", () => {
        player.play();
      });

      player.on("play", () => {
        this.isStreaming = true;
        this.$emit("streaming-updated", this.isStreaming);
      });

      player.on("error", () => {
        this.isStreaming = false;
        this.$emit("streaming-updated", this.isStreaming);
        if (!this.isReloading) {
          this.isReloading = true;
          setTimeout(() => {
            if (
              window.location.hostname === "localhost" ||
              window.location.hostname === "127.0.0.1"
            ) {
              this.loadOvenPlayerScript().then(() => {
                this.setupPlayer();
                this.isReloading = false;
              });
            } else {
              this.setupPlayer();
              this.isReloading = false;
            }
          }, 1000);
        }
      });
    },
  },
};
</script>
